<template>
    <header class="text-white">
        <h3>Oops! Page Not Found</h3>
    </header>
    <main class="text-white">
        <p>Hi,<br/>
        We are unable to locate the page you are looking for. Try the below:
        <ol>
            <li><router-link to="/home">Go to Home Page</router-link></li>
            <li><router-link to="/">Go to Login Page</router-link></li>
            <li><router-link to="/register">Go to Register Page</router-link></li>
        </ol>
        </p>
    </main>
    <section class="footer text-white">
        Jubatus Technologies  &copy; 2022. All rights reserved.
    </section>
</template>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/404.css';  
</style>